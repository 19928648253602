/* ---------------------------------------------- /*
 * Outlook
/* ---------------------------------------------- */
$(document).ready(function() {
  /* ---------------------------------------------- /*
   * Area Outlook - Landing Page Accordian Group
  /* ---------------------------------------------- */
    var $panels = $('.area-panel .panel-collapse');
    $panels.on('show.bs.collapse', function() {
      $panels.not(this).collapse('hide');
      $('.area-heading').removeClass('active');
      $(this).parent().find('.area-heading').addClass('active');
      $(this).parent().find('.fa').first().toggleClass('fa-angle-down fa-angle-up');
    }); 

    $panels.on('hidden.bs.collapse', function() {
      $(this).parent().find('.area-heading').removeClass('active');      
      $(this).parent().find('.fa').first().toggleClass('fa-angle-up fa-angle-down');
    }); 

    $('.block-area-landing .i-tabs li').on('click',function() {
      var area_name = $(this).text();
      $('.area-name').text(area_name);
    });
  /* ---------------------------------------------- /*
   * Area Outlook - Overview Page Nearby-List Accordian
  /* ---------------------------------------------- */  
    $('.definition-button').on('click', function() {
      $(this).find('i:eq(1)').toggleClass('fa-angle-down fa-angle-up');
    });

    $('.nearby-title').on('click', function(){     
      $(this).parent().next().collapse('toggle');
      $(this).find('i').toggleClass('fa-angle-up fa-angle-down');
    });
  /* ---------------------------------------------- /*
   * Project Outlook - Overview Page Nearby-List Accordian
  /* ---------------------------------------------- */
    var moreNearby = $('.project-nearby .nearby-more');
    moreNearby.on('show.bs.collapse', function() {
      $(this).siblings('.more-link').find('.fa').toggleClass('fa-angle-down fa-angle-up');
    });
    moreNearby.on('hidden.bs.collapse', function() {
      $(this).siblings('.more-link').find('.fa').toggleClass('fa-angle-up fa-angle-down');
    });
});