
  $(window).load(function() {
  // $(document).ready(function() {
  var headerM = $('.header-mobile-view'),
      navHeader = $('nav.nav-new.navbar-new'),
      mSearch = $('.m-news-search header-search-mazada fa.fa-search');

  var downScroll = 0;

  if ($(window).width() < 768) {
    $(window).scroll(function(event){
        var scroll = $(this).scrollTop();
        if (scroll > downScroll){  
          // navHeader.hide();
          navHeader.removeClass('in').addClass('out');
          // console.log('out!'); 
          // }
        } else { 
          // navHeader.show();
          // mSearch.show();
          navHeader.removeClass('out').addClass('in');
          // console.log('in!');
        }
        downScroll = scroll;
    });
  }
  else {
    // do nothing 
  }

});

