$(window).load(function() {
  var dfpElemPre = '#div-gpt-ad-',
  // prod 1610010292318, staging 1610003086242
  dfpId = '1610010292318-0',
  dfpStoSpFullId = '.sto-singlepage' + ' ' + dfpElemPre + dfpId,
  stoSpEl = $('.sto-singlepage'),
  stoSpElin = $('.sto-singlepage.in'),
  stospClose = $('.sto-singlepage .slide-close'),
  stospDuration = 180,
  stospCookieNm = 'main_sto_singlepage';
  // var stoMinute = 1440; 24 hr, 480: 8hr, 180: 3hr, 0: 0hr
  
  var expTime = new Date();
  expTime.setTime(expTime.getTime() + (stospDuration * 60 * 1000));
  $stospCookie = $.cookie(stospCookieNm); 

  function ShowSto(){   
    $(stoSpEl).show();
    $(stoSpEl).addClass('in'); 
  }
  function HideSto(){   
    $(stoSpEl).removeClass('in'); 
  }

  $.fn.hasAttr = function(name) {  
    return this.attr(name) !== undefined;
  };

  if(($(dfpStoSpFullId).css('display') == 'none') || !$(dfpStoSpFullId).hasAttr('data-google-query-id')){ 
    HideSto();  
    $(stoSpEl).hide();
    console.log('no sto SP');
  }
  else {
    ShowSto();
    if ($stospCookie == null) { 
      //do here
      $(stospClose).on('click', function(){
        $(this).parent().parent().removeClass('in');
        $.cookie(stospCookieNm, 'sto-hide', { path: '/', expires: expTime });
        HideSto();
      });
    }
    else {
      // cookie at work
      HideSto();
      console.log('sto cookies work - top');
    }

  }

  $(stospClose).on('click', function(){
    $(this).parent().parent().removeClass('in'); 
    $.cookie(stospCookieNm, 'sto-hide', { path: '/', expires: expTime });
    // HideSto();
    console.log('sto cookies work - bottom');
  });
 
  setTimeout(function() { 
    $(stoSpEl).removeClass('in'); 
  }, 10400);
});


// $(window).scroll(function() {
//   var sto = $('.sto-singlepage');
//   if(sto.hasClass('in')){
//     sto.removeClass('in');
//   }
// });



