(function (window, $) {
  "use strict";
  var RESPONSIVE_WIDTH = 768;
  var $window = $(window);
  var Collapse = $.fn.collapse.Constructor;

  function responsiveCall() {
    var width = $window.width();
    var isResponsive = width < RESPONSIVE_WIDTH;
    $("[data-toggle=responsiveCollapse]").each(function () {
      ResponsiveCollapse.init(this, isResponsive ? "simpleHide" : "simpleShow");
      isResponsive ? disableHeaderLink(this) : enableHeaderLink(this)
    })
  }

  function disableHeaderLink(el) {
    var $links = $(el).find("a");
    if ($links.length) {
      $links.on("click", function (e) {
        e.preventDefault()
      })
    }
  }

  function enableHeaderLink(el) {
    var $links = $(el).find("a");
    if ($links.length) {
      $links.off("click")
    }
  }

  function ResponsiveCollapse(element, options) {
    Collapse.call(this, element, options)
  }
  ResponsiveCollapse.prototype = Object.create(Collapse.prototype);
  ResponsiveCollapse.prototype.toggle = function () {
    if ($(window).width() < 768) {
      Collapse.prototype.toggle.call(this)
    }
  };
  ResponsiveCollapse.prototype.simpleShow = function () {
    if (this.$element.hasClass("in")) {
      return
    }
    var startEvent = $.Event("show.bs.collapse");
    this.$element.trigger(startEvent);
    if (startEvent.isDefaultPrevented()) {
      return
    }
    var dimension = this.dimension();
    this.$element.removeClass("collapse").addClass("collapsing")[dimension](0);
    this.transitioning = 1;
    var complete = function () {
      this.$element.removeClass("collapsing").addClass("in")[dimension]("auto");
      this.transitioning = 0;
      this.$element.trigger("shown.bs.collapse")
    };
    if (!$.support.transition) {
      return complete.call(this)
    }
    var scrollSize = $.camelCase(["scroll", dimension].join("-"));
    this.$element.one($.support.transition.end, $.proxy(complete, this)).emulateTransitionEnd(350)[dimension](this.$element[0][scrollSize])
  };
  ResponsiveCollapse.prototype.simpleHide = function () {
    if (!this.$element.hasClass("in")) {
      return
    }
    var startEvent = $.Event("hide.bs.collapse");
    this.$element.trigger(startEvent);
    if (startEvent.isDefaultPrevented()) {
      return
    }
    var dimension = this.dimension();
    this.$element[dimension](this.$element[dimension]())[0].offsetHeight;
    this.$element.addClass("collapsing").removeClass("collapse").removeClass("in");
    this.transitioning = 1;
    var complete = function () {
      this.transitioning = 0;
      this.$element.trigger("hidden.bs.collapse").removeClass("collapsing").addClass("collapse")
    };
    if (!$.support.transition) {
      return complete.call(this)
    }
    this.$element[dimension](0).one($.support.transition.end, $.proxy(complete, this)).emulateTransitionEnd(350)
  };
  ResponsiveCollapse.getTarget = function (element) {
    var $elt = $(element);
    var href;
    return $elt.attr("data-target") || (href = $elt.attr("href")) && href.replace(/.*(?=#[^\s]+$)/, "")
  };
  ResponsiveCollapse.init = function (element, defaultOption) {
    var $elt = $(element);
    var target = ResponsiveCollapse.getTarget(element);
    var $target = $(target);
    var data = $target.data("bs.collapse");
    var option = data ? defaultOption : $elt.data();
    var parent = $elt.attr("data-parent");
    var $parent = parent && $(parent);
    if (!data || !data.transitioning) {
      if ($parent) {
        $parent.find('[data-toggle=responsiveCollapse][data-parent="' + parent + '"]').not($elt).addClass("collapsed")
      }
      $elt[$target.hasClass("in") ? "addClass" : "removeClass"]("collapsed")
    }
    $target.responsiveCollapse(option)
  };
  ResponsiveCollapse.DEFAULTS = $.extend(Collapse.DEFAULTS, {});
  $.fn.responsiveCollapse = function (option) {
    return this.each(function () {
      var $this = $(this);
      var data = $this.data("bs.collapse");
      var options = $.extend({}, ResponsiveCollapse.DEFAULTS, $this.data(), typeof option == "object" && option);
      if (!data && options.toggle && option == "show") {
        option = !option
      }
      if (!data) {
        $this.data("bs.collapse", data = new ResponsiveCollapse(this, options))
      }
      if (typeof option == "string") {
        data[option]()
      }
    })
  };
  $.fn.responsiveCollapse.Constructor = ResponsiveCollapse;
  $(document).on("click.bs.collapse.data-api", "[data-toggle=responsiveCollapse]", function (e) {
    if (!$(this).attr("data-target")) {
      e.preventDefault()
    }
    ResponsiveCollapse.init(this, "toggle")
  });
  $window.on("resize.bs.collapse", responsiveCall);
  $(responsiveCall)
})(window, window.jQuery);