/* ---------------------------------------------- /*
 * iTabs
/* ---------------------------------------------- */
// Function
function iTabDropdown() {
  var itab = $('.i-tabs');
  itab.each(function(e) {
    var tabMenu   = $(this).find('.dropdown > .dropdown-menu'),
        tabToggle = $(this).find('.dropdown > .dropdown-toggle'),
        tabNum    = $(this).attr('data-num') ? $(this).data('num') - 1 : 2;
    //- console.log(tabNum);
    if (window.innerWidth < 767) {
      if (!tabMenu.length > 0) {
        $(this)
          .find('li:gt('+ tabNum +')')
          .wrapAll('<li class="dropdown pull-right"><div class="dropdown-menu dropdown-theme dropdown-right"></div></li>');
      }
      if (!tabToggle.length > 0) {
        $(this)
          .find('.dropdown > .dropdown-menu')
          .before('<a href="#" data-toggle="dropdown" class="dropdown-toggle"><i class="ion-icon ion-navicon"></i></a>');
      }
    } else {
      if (tabMenu.length > 0) {
        $(this)
          .find('.dropdown > .dropdown-menu > li')
          .appendTo($(this));
        $(this)
          .find('.dropdown')
          .remove();
      }
    }
  });
}
$(document).ready(function() {
  iTabDropdown();
});
$(window).on('resize', function() {
  iTabDropdown();
});