$(window).load(function() {
  var dfpElemPre = '#div-gpt-ad-',
  // prod 1554101456137, staging 1554102106211
  dfpId = '1554101456137-0',
  dfpStoFullId = '.sto-ads' + ' ' + dfpElemPre + dfpId,
  stoEl = $('.sto-ads'),
  stoElin = $('.sto-ads.in'),
  stoClose = $('.sto-ads .slide-close'),
  stoDuration = 240,
  stoCookieNm = 'main_sto';
  // var stoMinute = 1440; 24 hr, 480: 8hr, 180: 3hr, 0: 0hr
  
  var expTime = new Date();
  expTime.setTime(expTime.getTime() + (stoDuration * 60 * 1000));
  $stoCookie = $.cookie(stoCookieNm); 

  function ShowSto(){  
    // setTimeout(function(){
    //   $(stoEl).addClass('in');
    // },300);
    $(stoEl).show();
    $(stoEl).addClass('in'); 
  }
  function HideSto(){  
    // setTimeout(function(){
    // $(stoEl).removeClass('in');
    // },600);
    $(stoEl).removeClass('in');
    // $(stoEl).hide(); 
  }

  $.fn.hasAttr = function(name) {  
    return this.attr(name) !== undefined;
  };

  if(($(dfpStoFullId).css('display') == 'none') || !$(dfpStoFullId).hasAttr('data-google-query-id')){ 
    HideSto();  
    $(stoEl).hide();
    console.log('no sto');
  }
  else {
    ShowSto();
    if ($stoCookie == null) { 
      //do here
      $(stoClose).on('click', function(){
        $(this).parent().parent().removeClass('in');
        $.cookie(stoCookieNm, 'sto-hide', { path: '/', expires: expTime });
        HideSto();
      });
    }
    else {
      // cookie at work
      HideSto();
      console.log('sto cookies work - top');
    }

  }

  $(stoClose).on('click', function(){
    $(this).parent().parent().removeClass('in'); 
    $.cookie(stoCookieNm, 'sto-hide', { path: '/', expires: expTime });
    // HideSto();
    console.log('sto cookies work - bottom');
  });
 

  // else if ($('body').hasClass(excptThisPage) || $('body').hasClass(excptThisPage2)) {
  //   $('.sto-ads').hide();  
  // }

  // if cookies set - uncomment the below code
  //need to comment this for video sto
  setTimeout(function() { 
    $(stoEl).removeClass('in'); 
  }, 10400);
});



// $(window).scroll(function() {
//   var sto = $('.sto-ads');
//   if(sto.hasClass('in')){
//     sto.removeClass('in');
//   }
// });



