/* ---------------------------------------------- /*
 * Hero
/* ---------------------------------------------- */
$(window).on('load scroll resize', function() {
  var heades = $('.header'),
      heroes = $('.hero'),
      maines = $('.main');
  if (window.innerWidth > 1199 && window.outerWidth > 1199) {
    if ($(this).scrollTop() > parseInt(maines.innerHeight() - heroes.innerHeight())) {
      heroes.addClass('sticky');
      heroes.css('top', parseInt(maines.innerHeight() - heroes.innerHeight() + heades.innerHeight()) + 'px');
    } else {
      heroes.removeClass('sticky');
      heroes.removeAttr('style');
    }
  } else {
    heroes.removeClass('sticky');
    heroes.removeAttr('style');
  }
});