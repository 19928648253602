$(window).load(function() {
    var dfpElemPre           = '#div-gpt-ad-',
    // nextprod 1590047310707 prod:1555649770722 staging: 1555649535215
    dfpCatfishId         = '1555649770722-0', // catfish prod id 
    excptBmpaPage             = 'page-node-39', //bmpa page
    cfEl =  $('.catfish-ads'),
    dfpCatfishFullId     = '.catfish-ads' + ' ' + dfpElemPre + dfpCatfishId,
    cfClose = $('.catfish-ads .slide-close'),
    cpDuration = 1440,
    cookieNm = 'catfish-18may';
    // var catfishMinute = 1440; 24 hr, 720: 12hr, 480: 8hr, 360: 6hr,  180: 3hr,  0: 0hr 
    //1440 for belowmarket, 0 for fireside chat
    var expCfTime = new Date();
    expCfTime.setTime(expCfTime.getTime() + (cpDuration * 60 * 1000));
    $cfCookie = $.cookie(cookieNm); 

    function ShowCatfish(){  
        setTimeout(function(){
        $(cfEl).addClass('in');
        },600);
        $(cfEl).show();
    }
    function HideCatfish(){ 
        // $(cfEl).removeClass('in');
        setTimeout(function(){
        $(cfEl).removeClass('in');
        },600);
        // $(cfEl).hide();
    }

    $.fn.hasAttr = function(name) {  
        return this.attr(name) !== undefined;
    };
    
    if(($(dfpCatfishFullId).css('display') == 'none') || !$(dfpCatfishFullId).hasAttr('data-google-query-id')){  
        HideCatfish(); 
        console.log('no catfish');
    }
    // else {
    //     ShowCatfish();
    //     console.log('catfish here');
    // }
    else {
        
        ShowCatfish();
        if ($cfCookie == null) { 
            //do here
            $(cfClose).on('click', function(){
              $(this).parent().parent().removeClass('in');
              $.cookie(cookieNm, 'showed', { path: '/', expires: expCfTime });
              HideCatfish();
            });
        }
        else {
            // cookie at work
            HideCatfish(); 
            console.log('catfish cookies - top');
        }
    }

    
    $(cfClose).on('click', function(){
        $(this).parent().parent().removeClass('in');
        // var expCfTime = new Date(); 
        // expCfTime.setTime(expCfTime.getTime() + (cpDuration * 60 * 1000));
        // $cfCookie = $.cookie('catfish-15may'); 
        $.cookie(cookieNm, 'showed', { path: '/', expires: expCfTime });
        // HideCatfish();
        console.log('catfish cookies - bottom');
    });
});