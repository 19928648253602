/* ---------------------------------------------- /*
 * Profile upload file preview
/* ---------------------------------------------- */
$(document).ready(function() {
  $("#file").change(function() {
  $('#choosen').attr('src', '#');
    readURL(this);
  });
  
  function readURL(input) {
    if (input.files && input.files[0]) {
      var reader = new FileReader();            
      reader.onload = function (e) {
        $('#choosen').attr('src', e.target.result);
        imgType($('#choosen'));
      }            
      reader.readAsDataURL(input.files[0]);
    }
  }

  function imgType(imgName){
    var checkImg = imgName;
    $('.display img').removeClass('landscape portrait')
    .addClass(checkImg.width() > checkImg.height() ? 'landscape' : 'portrait');
  }
});