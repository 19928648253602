//parallax code
$(document).ready(function() {
// $(window).on('load', function () {
    var parallaxUnit = $('.skin-ads-parallax > div'),
        parallaxFlg = $('.skin-ads-parallax'),
        parallaxAdCon = $('.skin-ads-parallax-wrapper'),
        // contentPara = $('body.page-content .news-article.news-details').find('p:nth-child(3)') || $('body.node-type-tep-blogs .news-article.news-details').find('p:nth-child(3)'),
        contentPara = $('body.page-content .news-article.news-details, body.node-type-tep-blogs .news-article.news-details').find('p:nth-child(3)'), 
        contentParaEnd = $('body.page-content .news-article.news-details, body.node-type-tep-blogs .news-article.news-details').find('p:nth-child(4)'), 
        content_ParaOption = $('body.page-content .news-article.news-details, body.node-type-tep-blogs .news-article.news-details').find('p:nth-child(6)'), 
        content_ParaOption_end = $('body.page-content .news-article.news-details, body.node-type-tep-blogs .news-article.news-details').find('p:nth-child(7)'), 
        bodyContent = $('body.page-content').find('.main-content'),
        prlxLabel = '<div class="prlxlabel">Scroll to continue with content</div>',
        prlxLabelEnd = '<div class="prlxlabel end">Scroll to continue with content</div>';
    
    if ($(window).width() < 768) {
        if((parallaxFlg.css('display') == 'none')){
            parallaxAdCon.hide();  
            contentPara.css({'margin-bottom': '0'}); 
            // parallaxFlg.removeClass('prlx-label');
        }
        else if (!parallaxFlg.length) {
            parallaxAdCon.hide(); 
            contentPara.css({'margin-bottom': '0'});  
            // parallaxFlg.removeClass('prlx-label');
        }
        else {

            if ($('body').hasClass('page-content-1906510') || $('body').hasClass('page-content-1906940')) {            
            // if (bodyContent.has('.news-owl').length > 0) {
                content_ParaOption.append(prlxLabel);
                content_ParaOption_end.prepend(prlxLabelEnd);
                content_ParaOption.css({'margin-bottom': '444px'}); 
                console.log('test test');
                
                if($('body').hasClass('node-type-tep-blogs')) {
                    parallaxFlg.css({'position': 'fixed', 'top': '52px', 'left': '0'});
                }
                else {
                    parallaxFlg.css({'position': 'fixed', 'top': '52px', 'left': '0'});
                    // parallaxFlg.addClass('prlx-label');
                }
                $(window).scroll(function() {
                    parallaxAdCon.show(); 
                    if (window.scrollY > 3600 ) {
                        parallaxAdCon.hide(); 
                    } else {
                        if((parallaxFlg.css('display') == 'none')){
                            parallaxAdCon.hide();  
                            content_ParaOption.css({'margin-bottom': '0'});
                        }
                        else {
                            parallaxAdCon.show(); 
                        }
                    }
                });
            }
            else {
                contentPara.append(prlxLabel);
                contentParaEnd.prepend(prlxLabelEnd);
                contentPara.css({'margin-bottom': '444px'}); 
                // if ($(window).width() < 768) {
                //     contentPara.css({'margin-bottom': '600px'}); 
                // }
                // if ($(window).width() < 414) {
                //     contentPara.css({'margin-bottom': '508px'}); 
                // }
                if($('body').hasClass('node-type-tep-blogs')) {
                    parallaxFlg.css({'position': 'fixed', 'top': '52px', 'left': '0'});
                }
                else {
                    parallaxFlg.css({'position': 'fixed', 'top': '52px', 'left': '0'});
                    // parallaxFlg.addClass('prlx-label');
                }
                
                // $(window).scroll(function() {
                //     parallaxAdCon.show(); 
                //     if (window.scrollY > 1800 ) {
                //         parallaxAdCon.hide(); 
                //     } else {
                //         parallaxAdCon.show(); 
                //     }
                // });
                $(window).scroll(function() {
                    parallaxAdCon.show(); 
                    if (window.scrollY > 2600 ) {
                        parallaxAdCon.hide(); 
                    } else {
                        if((parallaxFlg.css('display') == 'none')){
                            parallaxAdCon.hide();  
                            contentPara.css({'margin-bottom': '0'});
                        }
                        else {
                            parallaxAdCon.show(); 
                        }
                    }
                });
            }
     
        }
    } 

});