$(window).load(function() {
  var dfpElemPre  = '#div-gpt-ad-', 
  // prod 1554092556791, staging 1554089397614
  stobigId = '1554092556791-0',
  stopbigFullId = '.sto-big-animation' + ' ' + dfpElemPre + stobigId,
  excptThisPage             = 'page-node-37', //dkn page
  stobigEl = $('.sto-big-animation'), 
  stobigClose = $('.sto-big-animation .slide-close'),
  stobigDuration = 180,
  stobigCookieNm = 'main_stobig';
  // var stoMinute = 1440; 24 hr, 480: 8hr, 180: 3hr, 0: 0hr

  var xstobigTime = new Date();
  xstobigTime.setTime(xstobigTime.getTime() + (stobigDuration * 60 * 1000));
  $stobigCookie = $.cookie(stobigCookieNm); 

  function ShowStobig(){  
    // setTimeout(function(){
    //   $(stobigEl).addClass('in');
    // },300);
    $(stobigEl).show();
    $(stobigEl).addClass('in'); 
  }
  function HideStobig(){  
    // setTimeout(function(){
    // $(stobigEl).removeClass('in');
    // },600);
    $(stobigEl).removeClass('in');
    // $(stobigEl).hide(); 
  }

  $.fn.hasAttr = function(name) {  
    return this.attr(name) !== undefined;
  };

  if(($(stopbigFullId).css('display') == 'none') || !$(stopbigFullId).hasAttr('data-google-query-id')){ 
    HideStobig();  
    $(stobigEl).hide();
    console.log('no stobig');
  }
  else {
    ShowStobig();
    if ($stobigCookie == null) { 
      //do here
      $(stobigClose).on('click', function(){
        $(this).parent().parent().removeClass('in');
        $.cookie(stobigCookieNm, 'stobig-hide', { path: '/', expires: xstobigTime });
        HideStobig();
      });
    }
    else {
      // cookie at work
      HideStobig();
      console.log('stobig cookies work - top');
    }

  }

  $(stobigClose).on('click', function(){
    $(this).parent().parent().removeClass('in'); 
    $.cookie(stobigCookieNm, 'stobig-hide', { path: '/', expires: xstobigTime });
    // HideStobig();
    console.log('stobig cookies work - bottom');
  });

  // if cookies set - uncomment the below code
  //need to comment this for video sto
  setTimeout(function() { 
    $(stobigEl).removeClass('in'); 
  }, 10400);
 
});



// $(window).scroll(function() {
//   var sto = $(dfpStoBigAnimContainer);
//   if(sto.hasClass('in')){
//     sto.removeClass('in');
//   }
// });