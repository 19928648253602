/* ---------------------------------------------- /*
 * Common
/* ---------------------------------------------- */
$(document).ready(function() {
  $('.agt-registration').addClass('in');

  // Navigation Selector
  $(document).on('click', '[data-toggle="nav"]', function(e) {
    e.preventDefault();
    var item = $(this).data('target');
    if (item == 'self') {
      if ($(this).hasClass('active'))
        $(this).removeClass('active')
      else
        $(this).addClass('active')
    } else {
      if ($(this).closest(item).length < 1) {
        if ($(item).hasClass('active'))
          $(item).removeClass('active')
        else
          $(item).addClass('active')
      } else {
        if ($(this).closest(item).hasClass('active'))
          $(this).closest(item).removeClass('active')
        else
          $(this).closest(item).addClass('active')
      }
    }
    if ($(this).hasClass('input')) {
      $(this).toggleClass('active')
      $(this).find('i').toggleClass('fa-chevron-down fa-chevron-up');
    }
  });

  // Search Selector
  $('[data-toggle="search"]').on('click', function(e){
    var item = $(this).data('target');
    if ($(item).hasClass('active')) {
      $(item).removeClass('active');
    }
    else {
      $(item).addClass('active');
      setTimeout(function() {
        $(item).find('.search-bar input').focus();
      }, 100);
    }
  });

  // Expandable
  $('[data-toggle="expandable"]').each(function() {
    var rmtext = 'Read More <i class="material-icons">keyboard_arrow_down</i>',
        cltext = 'Collapse <i class="material-icons">keyboard_arrow_up</i>';

    $(this).append('<a class="readmore font-sans" role="button" title="Click to view more detail">' +
      'Read More <i class="material-icons">keyboard_arrow_down</i>' +
      '</a>');
    $(this).find('.readmore').on('click', function(e) {
      e.preventDefault();
      if ($(this).parent().hasClass('active')) {
        $(this).parent().removeClass('active');
        $(this).html(rmtext);
      } else {
        $(this).parent().addClass('active');
        $(this).html(cltext);
      }
    });


    // if($('.listing-expandable').height() < 100 ) {
    //   rmtext.hide();
    // }
  });


  //hide toggle expandable on short description
  $('body.page-property-detail').not('.cleanmode').each(function() {
      var elem = $('.page-property-detail .listing-expandable');
      var maxHeight = 260;
      elem.css('height', 'auto');
      console.log(elem.height());
      if (elem.height() <= maxHeight) {
        $('.readmore.font-sans').hide();
      }
      else {
        elem.css('height', maxHeight);
        $('.readmore.font-sans').show();
      }
    });

  $('body.page-property-detail.cleanmode').each(function() {
    var propReviewContent = $('.page-property-detail.cleanmode .listing-expandable').text();
    var prtrimcontent = $.trim(propReviewContent);
    if(prtrimcontent.length < 4000) {
      $('.readmore.font-sans').hide();
    }
    else {
      $('.readmore.font-sans').show();
    }

  });

  //show nothing when zero or lower




  // Tooltip
  $('[data-toggle="tooltip"]').tooltip();

  // Popover
  $('[data-toggle="popover"]').popover();

  // Lazyload
  $('.lazyload').lazyload({
    threshold : 0,
    effect : "fadeIn"
  });

  // Scrollbar
  $('.i-scrollbar.horizontal').mousewheel(function(e, delta) {
    this.scrollLeft -= (delta * 60);
    e.preventDefault();
  });
  $('.i-scrollbar').scrollbar();

  // Owl Carousel
  var iCarousel = $('.i-carousel'),
      imgCarousel = $('.image .owl-carousel');
  // Item Carousel
  iCarousel.each(function (i) {
    var ditem      = $(this).data('items'),
        items      = typeof ditem != "undefined" ? (ditem < 1 ? 1 : ditem) : 3,
        margin     = $(this).attr('data-margin') ? $(this).data('margin') : 20,
        autoplay   = $(this).attr('data-autoplay') ? true : false,
        loop       = $(this).attr('data-loop') ? true : false,
        resp       = $(this).data('responsive'),
        responsive = typeof resp != "undefined" ? $(this).attr('data-responsive').length > 0 ? $(this).data('responsive') : JSON.parse('{"0":{"items":1,"nav":true},"480":{"items":2,"nav":true},"1200":{"items":'+items+'}}') : null;
    // console.log(items + '/' + margin + '/' + autoplay + '/' + loop);
    $(this).owlCarousel({
      margin: margin,
      nav: true,
      lazyLoad: true,
      navText: ['<i class="ion-ios-arrow-left"></i>','<i class="ion-ios-arrow-right"></i>'],
      dots: false,
      items: items,
      autoplay: autoplay,
      loop: loop,
      responsive: responsive
    });
  });
  // Image Carousel (Single)
  imgCarousel.owlCarousel({
    margin: 0,
    items: 1,
    lazyLoad: true,
    loop: true,
    dots: false,
    // dotsData: true,
    nav: true,
    navText: ['<i class="ion-ios-arrow-left"></i>','<i class="ion-ios-arrow-right"></i>']
  });

  // news carousel
  newsCarousel();

  // tooptip for zing.my link in menu
  var zingLink = $('.header-nav a[href="http://zing.my/"]');
  zingLink.attr("data-placement","bottom");
  zingLink.attr("title","Zing.my");
  zingLink.tooltip();
  zingLink.attr("target","_blank");
});

window.newsCarousel = function(){
  $(".news-owl").owlCarousel({
    nav: true,
    slideSpeed : 300,
    paginationSpeed : 400,
    singleItem:true,
    items : 1,
    itemsDesktop : false,
    itemsDesktopSmall : false,
    itemsTablet: false,
    itemsMobile : false,
    autoplay:true,
    autoplayTimeout:3000,
    autoplayHoverPause:true,
    loop:true,
    navText: ['','']
  });
}
