// $(document).ready(function() {
// // $(window).load(function() {
//     // prod 1583460399043 staging 1583217453904 
//     var dfpInArticleId = $('#div-gpt-ad-1583460399043-0'), 
//         inArticleUnit = $('.ads-in-article'),
//     //   unitTimeOut = 7400,
//         inArticleInsert = $('body.node-type-tep-blogs .news-body.content p:nth-child(3)'); 
//         inArticleInsertM = $('body.node-type-tep-blogs #block-block-5'); 
        
//     if ($('body').hasClass('front') || (dfpInArticleId.css('display') == 'none')) {
//         inArticleUnit.hide();
//         console.log('front or none');
//     }
//     else { 
//         if ($(window).width() < 768) {
//         inArticleUnit.insertBefore(inArticleInsertM);
//         inArticleUnit.show();
//         }
//         else {
//         inArticleInsert.append(inArticleUnit);
//         inArticleUnit.show();
//         }
//         console.log('in-article appear');
//     }
// });
    