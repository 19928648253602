// staging xx
//new prod in news 1681892379070
//old  1652153514582

// $(document).ready(function() {
    $(window).load(function() { 
        var dfpinNewsId = $('#div-gpt-ad-1681892379070-0'), 
            inNewsUnit = $('.ads-in-content'),
        //  unitTimeOut = 7400,
        //  inNewsInsert = $('body.page-content .news-body.content p:nth-child(3)'),
        //  inNewsInsertM = $('body.page-content #block-block-5'),
            inNewsCarousel = $('body.page-content #adInContent');
            
        if ($('body').hasClass('front') || (dfpinNewsId.css('display') == 'none')) {
            inNewsUnit.hide();
            console.log('front or none');
        }
        else { 
            if ($(window).width() < 768) {
            inNewsUnit.appendTo(inNewsCarousel);
            googletag.cmd.push(function() {
                googletag.pubads().refresh();
            });
            inNewsUnit.show();
            }
            else {
            // inNewsInsert.append(inNewsUnit);
            inNewsUnit.appendTo(inNewsCarousel);
            googletag.cmd.push(function() {
                googletag.pubads().refresh();
            });
            inNewsUnit.show();
            }
            console.log('in-content appear');
        }
    });
        