// $(document).ready(function() {
$(window).load(function() {
  // prod 1666774521549 staging 1666685476959 
  var mfId = '#div-gpt-ad-1666774521549-0',  
      mrecFloating =  $('.ads-mrec-floating'),
      mfFullId = '.ads-mrec-floating' + ' ' + mfId,
      mfClose = $('.ads-mrec-floating .pop-slide-close'),
      mfDuration = 180,
      mfTimeOut = 10400,
      cookiemfNm = 'mfCookie';

  var expMfTime = new Date();
      expMfTime.setTime(expMfTime.getTime() + (mfDuration * 60 * 1000));
      $mfCookie = $.cookie(cookiemfNm); 

  function ShowMrecFloating(){  
    setTimeout(function(){
        mrecFloating.addClass('in');
    },600);
    mrecFloating.show();
  }
  function HideMrecFloating(){ 
    // mrecFloating.removeClass('in');
    setTimeout(function(){
        mrecFloating.removeClass('in');
    },600);
    // mrecFloating.hide();
  }
  $.fn.hasAttr = function(name) {  
    return this.attr(name) !== undefined;
  };

  // if(($(mfFullId).css('display') == 'none')){ 
  if(($(mfFullId).css('display') == 'none') || !$(mfFullId).hasAttr('data-google-query-id')){ 
    HideMrecFloating(); 
    console.log('no mrec floating ');
  }
  else {
        
    ShowMrecFloating();
    if ($mfCookie == null) { 
      //do here
      $(mfClose).on('click', function(){
        $(this).parent().parent().removeClass('in');
        $.cookie(cookiemfNm, 'mfshowed', { path: '/', expires: expMfTime });
        HideMrecFloating();
      });
    }
    else {
      // cookie at work
      HideMrecFloating(); 
      console.log('mrec floating cookies work - top');
    }
  }

  $(mfClose).on('click', function(){
    $(this).parent().parent().removeClass('in'); 
    $.cookie(cookiemfNm, 'mfshowed', { path: '/', expires: expMfTime });
    // HideMrecFloating();
    console.log('mrec floating cookies work - bottom');
  });

  setTimeout(function() { 
    mrecFloating.removeClass('in'); 
  }, mfTimeOut);
      
    // if ($('body').hasClass('front') || (mfFullId.css('display') == 'none')) {
    //     compactOverlayUnit.hide();
    //     console.log('front or none')
    // }
    // else { 
    //     compactOverlayUnit.show();
    //     setTimeout(function() {
    //         compactOverlayUnit.addClass('in');
    //     },300); 
    //     $('.ads-compact-overlay .pop-slide-close').on('click', function() {
    //         $(this).parent().parent().removeClass('in');
    //     });

    //     // if((dfpCOverlayId.css('display') == 'none')){ 
    //     //     compactOverlayUnit.hide(); 
    //     // } 
    //     // else {
    //     //     compactOverlayUnit.show();
    //     //     setTimeout(function() {
    //     //         compactOverlayUnit.addClass('in');
    //     //     },300); 
    //     //     $('.ads-compact-overlay .pop-slide-close').on('click', function() {
    //     //         $(this).parent().parent().removeClass('in');
    //     //     });
    //     // } 
    // }
    // setTimeout(function() { 
    //     if (compactOverlayUnit.hasClass('in')) {
    //         compactOverlayUnit.removeClass('in'); 
    //         // compactOverlayUnit.hide();
    //     } 
    // }, unitTimeOut);
});