/* ---------------------------------------------- /*
 * iInput
/* ---------------------------------------------- */
var iInputs,
    iSelect,
    iFile,
    iRange;

$(window).bind('pageshow', function() {
  // Variable
  iInputs = $('.i-form .input.animate input, .i-form .input.animate textarea'),
  iFile   = $('.i-form .file input'),
  iRange  = $('.i-form .input.range.indicator input[type="range"]');
  // Input
  iInputs.each(function() {
    if ($(this).val().length !== 0) {
      $(this).closest('.input').addClass('focused');
      // console.log('focus');
    }
  });
});

$(document).ready(function() {
  // Variable
  iInputs = $('.i-form .input.animate input, .i-form .input.animate textarea'),
  iSelect = $('.i-form .select.animate select'),
  iFile   = $('.i-form .file input'),
  iRange  = $('.i-form .input.range.indicator input[type="range"]');
  // Input
  iInputs.on('focus', function() {
    if ($(this).val().length === 0) {
      $(this).closest('.input').addClass('focused');
      // console.log('focus');
    }
  });
  iInputs.on('blur', function() {
    if ($(this).val().length === 0) {
      $(this).closest('.input').removeClass('focused');
      // console.log('blur');
    }
  });
  // Select
  iSelect.on('click', function() {
    $(this).closest('.select').toggleClass('focused');
    // console.log('click');
  });
  iSelect.on('blur', function() {
    $(this).closest('.select').removeClass('focused');
    // console.log('blur');
  });
  // File
  iFile.on('change', function() {
    if ($(this).val().length >= 1)
      $(this).closest('.file').addClass('focused');
    else
      $(this).closest('.file').removeClass('focused');
    // console.log('change');
  });
  // Range
  iRange.on('input', function() {
    var step    = $(this).attr('step'),
        stepStr = step.toString(),
        stepPos = stepStr.indexOf('.'),
        stepTtl = stepStr.length,
        stepDgt = stepTtl - (stepPos + 1);
    if (!isNaN(step) && stepPos != -1) {
      $(this).siblings('label').attr('data-num', parseFloat($(this).val()).toFixed(stepDgt));
    } else {
      $(this).siblings('label').attr('data-num', $(this).val());
    }
  });
});