/* ---------------------------------------------- /*
 * Agent Phone Masked
/* ---------------------------------------------- */
$(document).ready(function() {
  // Click 2 display
  $('.agent-phone-mask').on('click', function() {
    $(this).css({ 'left':'100%' });
    $(this).siblings('.agent-phone-masked').addClass('active');
  });
  $('.agent-phone-masked').on('click', function(e){
    if (!$(this).hasClass('active')) {
      e.preventDefault();
      $(this).prev('.agent-phone-mask').css({ 'left':'100%' });
      $(this).addClass('active');
    }
  });
  // Lite - 1st click to show 2nd click to call
  $('[data-toggle="agent-phone"]').on('click', function(e) {
    if ($(this).hasClass('active')) {
      var num = $(this).find('.agent-phone-number').text();
      location.href = 'tel:' + num;
    } else {
      $(this).addClass('active');
    }
  });
  //Agent Landing Page Grid onclick
  $('.block-agent-region .cover').on('click', function() {
      $(this).find('.card-label').text('Kedah');
      $(this).find('.card-label').addClass('revert');
      $('.block-agent-region .card-box:nth-child(n+8)').css("display","block");

  });
  //Agent Listing Page agent contact unmask
  $('.unMask').on('click', function() {
    var number = $(this).parent().data('number');
    $(this).parent().find('.mask').text(number);
    $(this).hide();
  });
});