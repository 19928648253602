$(window).load(function() {
    var dfpElemPre  = '#div-gpt-ad-',
        dfpSkinAdId       = '1560483658582-0', // prod: 1560483658582, staging:1560151442822
        dfpFullSkinAdId     = '.skin-ads' + ' ' + dfpElemPre + dfpSkinAdId,
        skinAds = $('.skin-ads-wrapper .skin-ads'),
        mainContent = $('.news-wope-theme'),
        newsWrapr = $('body.page-content .news-content-wrapper');
   
    //not using the scroll area
    if ($(window).width() > 768) {
        $(window).scroll(function() {
            if (window.scrollY > 1) {
                $(skinAds).addClass('pos-fixed');
                $('.header-news').addClass('pos-top');
            } 
            else if (window.scrollY < 1) {
                $(skinAds).removeClass('pos-fixed');
                $('.header-news').removeClass('pos-top');
            }
            else {
                $(skinAds).removeClass('pos-fixed');
            }
        });     
      
        $(skinAds).each(function () {
            if($(this).css('display') == 'none' || $(this).css('cursor', 'pointer')){ 
            // if($(this).css('display') == 'none'){ 
                console.log('skin ads - none');
                // $(this).hide();
                // predefine on news-core line 5344
                // $(mainContent).css({'margin-top': '186px'});
                // $(newsWrapr).css({'background': '#eee'});
            }
            // else if($(this).css('display') == 'none'){ 
            //     console.log('skin ads - none');
            //     // $(this).hide();
            //     // predefine on news-core line 5344
            //     // $(mainContent).css({'margin-top': '186px'});
            //     // $(newsWrapr).css({'background': '#eee'});
            // }
            else { 
                console.log('skin ads - show');
                $(this).show();
                $(this).css({'cursor': 'pointer'});
                $(newsWrapr).css({'background': 'none'});
                if (window.outerWidth > 1500 ) {
                    $(mainContent).css({'top': '294px', 'margin-bottom': '20px', 'transition': 'all .5s .2s ease-in-out'});
                } 
                else if (window.outerWidth == 1440 || window.outerWidth == 1366 || window.outerWidth == 1280  ) {
                    $(mainContent).css({'top': '260px', 'margin-bottom': '20px', 'transition': 'all .5s .2s ease-in-out'});
                }
                else if (window.outerWidth == 1024 ) {
                    $(mainContent).css({'top': '197px', 'margin-bottom': '20px', 'transition': 'all .5s .2s ease-in-out'});
                }
                else if (window.outerWidth = 768 ) {
                    $(mainContent).css({'top': '147px', 'margin-bottom': '20px', 'transition': 'all .5s .2s ease-in-out'});
                }
                else {
                //   do nothing
                }           
            } 
        }) 
    }
  });