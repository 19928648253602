$(document).ready(function() { 
    // var AppArea = $('body').hasClass('one-sidebar'),
    var AppArea = 'body.one-sidebar',
        AppBanner = $('#app-banner'),
        AppClose = $(AppArea + ' .app-close'),
        adParallax = $(AppArea + ' .skin-ads-parallax'),
        mainContent = $(AppArea + ' .main-content'),
        topNav = $(AppArea + ' .top-nav'),
        newScroller = $(AppArea + ' .news-top-stories'),
        sliderPosition = $(AppArea + ' .slider-position'),
        iSearch = $(AppArea + ' .m-news-search i.fa.fa-search'),
        newsUrl = window.location.href,
        newsSplit = newsUrl.split('/'),
        newsId = newsSplit[newsSplit.length-2],
        AppUrl = 'https://www.edgeprop.my/appstore'; 
        // console.log(newsId);
        
    function ShowAppBanner(){ 
        if ($(window).width() < 768) {
            var expAppBanner = new Date();
            var exbMin = 1440; //a day
            expAppBanner.setTime(expAppBanner.getTime() + (exbMin * 60 * 1000));
            $abCookie = $.cookie('app-banner'); 

            if ($abCookie == null) { 
                //do here
                AppBanner.show(); 
                mainContent.addClass('app-maincontent')
                topNav.addClass('normalize-fix');
                newScroller.addClass('app-news-scroller');
                sliderPosition.addClass('app-slider-position');
                // iSearch.hide(); 
            }
            else {
                // cookie at work
                AppBanner.hide(); 
                console.log('app banner cookie at work')
            }

            }
        else {
            AppBanner.hide();
        }
    }
    function HideAppBanner(){ 
        AppBanner.hide();
        topNav.removeClass('normalize-fix');
        mainContent.removeClass('app-maincontent');
        newScroller.removeClass('app-news-scroller');
        sliderPosition.removeClass('app-slider-position');
        iSearch.show();
    }

    function setCookie() {
        var expAppBanner = new Date();
        var exbMin = 1440; //a day
        expAppBanner.setTime(expAppBanner.getTime() + (exbMin * 60 * 1000));
        $abCookie = $.cookie('app-banner');
    }


    $(AppBanner).click(function () {
        if($('body').hasClass('page-content')) {
            window.location = 'edgepropmy://news/' + newsId;
        } else {
            setTimeout(function() {
                window.open(AppUrl);
            }, 100);
            window.location = 'edgepropmy://';
        }
     });
    //  console.log('edgepropmy://news/' + newsId);

    $(AppClose).click(function() {
        var expAppBanner = new Date();
        var exbMin = 1440; //a day
        // var exbMin = 0; //a day
        expAppBanner.setTime(expAppBanner.getTime() + (exbMin * 60 * 1000));
        $abCookie = $.cookie('app-banner');
        $.cookie('app-banner', 'showed', { path: '/', expires: expAppBanner });
        HideAppBanner();
    });

    if($('body').hasClass('page-news') || $('body').hasClass('page-content') || $('body').hasClass('page-node-29') || $('body').hasClass('page-edgeproptv') || $('body').hasClass('page-pullout') || $('body').hasClass('page-events')) {
        $(window).scroll(function() { 
            if (window.scrollY > 1) {
                HideAppBanner(); 
                adParallax.css({'top': '0'});
            } else {
                ShowAppBanner();
                if ($(window).width() > 425) {
                    adParallax.css({'top': '81px'});
                }
                else {
                    adParallax.css({'top': '72px'});
                }
            }     
        });
    } else {
        HideAppBanner(); 
    }
});