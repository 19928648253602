// $(document).ready(function() {
$(window).load(function() {
  // prod 1583203402859 staging 1582603488987 
  var dfpcoId = '#div-gpt-ad-1583203402859-0',  
      coEl =  $('.ads-compact-overlay'),
      dfpcoFullId = '.ads-compact-overlay' + ' ' + dfpcoId,
      coClose = $('.ads-compact-overlay .pop-slide-close'),
      coDuration = 180,
      coTimeOut = 7400,
      cookiecoNm = 'cocookie';

  var expCoTime = new Date();
      expCoTime.setTime(expCoTime.getTime() + (coDuration * 60 * 1000));
      $coCookie = $.cookie(cookiecoNm); 

  function ShowCompactOverlay(){  
    setTimeout(function(){
      coEl.addClass('in');
    },600);
    coEl.show();
  }
  function HideCompactOverlay(){ 
    // coEl.removeClass('in');
    setTimeout(function(){
      coEl.removeClass('in');
    },600);
    // coEl.hide();
  }
  $.fn.hasAttr = function(name) {  
    return this.attr(name) !== undefined;
  };

  // if(($(dfpcoFullId).css('display') == 'none')){ 
  if(($(dfpcoFullId).css('display') == 'none') || !$(dfpcoFullId).hasAttr('data-google-query-id')){ 
    HideCompactOverlay(); 
    console.log('no c-overlay ');
  }
  else {
        
    ShowCompactOverlay();
    if ($coCookie == null) { 
      //do here
      $(coClose).on('click', function(){
        $(this).parent().parent().removeClass('in');
        $.cookie(cookiecoNm, 'coshowed', { path: '/', expires: expCoTime });
        HideCompactOverlay();
      });
    }
    else {
      // cookie at work
      HideCompactOverlay(); 
      console.log('c-overlay cookies work - top');
    }
  }

  $(coClose).on('click', function(){
    $(this).parent().parent().removeClass('in'); 
    $.cookie(cookiecoNm, 'coshowed', { path: '/', expires: expCoTime });
    // HideCompactOverlay();
    console.log('c-overlay cookies work - bottom');
  });

  setTimeout(function() { 
    coEl.removeClass('in'); 
  }, coTimeOut);
      
    // if ($('body').hasClass('front') || (dfpcoFullId.css('display') == 'none')) {
    //     compactOverlayUnit.hide();
    //     console.log('front or none')
    // }
    // else { 
    //     compactOverlayUnit.show();
    //     setTimeout(function() {
    //         compactOverlayUnit.addClass('in');
    //     },300); 
    //     $('.ads-compact-overlay .pop-slide-close').on('click', function() {
    //         $(this).parent().parent().removeClass('in');
    //     });

    //     // if((dfpCOverlayId.css('display') == 'none')){ 
    //     //     compactOverlayUnit.hide(); 
    //     // } 
    //     // else {
    //     //     compactOverlayUnit.show();
    //     //     setTimeout(function() {
    //     //         compactOverlayUnit.addClass('in');
    //     //     },300); 
    //     //     $('.ads-compact-overlay .pop-slide-close').on('click', function() {
    //     //         $(this).parent().parent().removeClass('in');
    //     //     });
    //     // } 
    // }
    // setTimeout(function() { 
    //     if (compactOverlayUnit.hasClass('in')) {
    //         compactOverlayUnit.removeClass('in'); 
    //         // compactOverlayUnit.hide();
    //     } 
    // }, unitTimeOut);
});