
  $(window).load(function() {
  // $(document).ready(function() {
  var shareBtn = $('.share-news-content'),
      shareBox = $('.share-news-content-box'),
      closeIco = 'https://media.edgeprop.my/assets/icons/share-color-24.svg',
      defIco = 'https://media.edgeprop.my/assets/icons/share-white-24.svg',
      closeBtn = $('.share.on-click'),
      btnIco = $('.share-news-content > img.ico'),
      catfishUnit = $('#div-gpt-ad-1555649770722-0');
  
  var downScroll = 0;
  $(window).scroll(function(event){
      var scroll = $(this).scrollTop();
      if (scroll > downScroll){
        // downscroll code
        // shareBtn.addClass('default').removeClass('in');
        
        // plan b
        // if(shareBox.hasClass('in')) {
        //   shareBox.removeClass('in');
        // }
        // else {
        //   shareBtn.css({'bottom': '-48px'});
        // }
        // if(shareBtn.hasClass('in on-click')) {
        //   shareBtn.removeClass('in on-click');
        // }
        // if(btnIco.attr('src') == closeIco) {
        //   $(btnIco).attr('src', defIco);
        // }

        //plan c 
        shareBtn.css({'bottom': '-48px'});

        if(shareBox.hasClass('in')) {
          shareBox.removeClass('in');
        }
        if(shareBtn.hasClass('in on-click')) {
          shareBtn.removeClass('in on-click');
        }
        if(shareBtn.hasClass('in catfish-extension on-click')) {
          shareBtn.removeClass('in catfish-extension on-click');
        }
        if(btnIco.attr('src') == closeIco) {
          $(btnIco).attr('src', defIco);
        }

      } else {
        // upscroll code
        // if($('#div-gpt-ad-1555649770722-0').length) {
        if((catfishUnit.css('display') == 'none')){ 
          shareBtn.attr('style', '').addClass('in');
        }
        else if (!catfishUnit.length) {
          shareBtn.attr('style', '').addClass('in');
          // console.log('no catfish');
        }
        else {
          // enable for catfish condition
          shareBtn.attr('style', '').addClass('in catfish-extension');  
        }
        // shareBox.removeClass('in');
        // shareBtn.addClass('in').animate({ bottom: '5px'}, 200);
        // shareBtn.animate({ bottom: '5px'}, 300);
      }
      downScroll = scroll;
  });
  

  function showBtnDefault () {
    $(shareBtn).removeClass('on-click');
    $(btnIco).attr('src', defIco);
    $(shareBox).removeClass('in');
    // $('body').css({'overflow': 'auto'});
    // $('body').unbind('touchmove');
    // $('body').unbind('touchmove', function(e){
    //   e.preventDefault();
    // });
  }

  $(shareBox).each(function () {
    $(this).on('click', function(){
      showBtnDefault();
    });
    
    $(shareBtn).on('click', function() {
      // $('body').css({'overflow': 'hidden', 'height': 'auto'});
      $(shareBox).addClass('in');
      $('.share-news-content > img.ico').attr('src', closeIco);
      $(this).addClass('on-click');   
    });
    var open = false;
    $(shareBtn).click(function() {
        open = !open;

        if(open) {
            // console.log('11');
        } else {
          showBtnDefault();
        }
    });
  });

  var shareShw = $('.share-news-content.in');
  // if($('#div-gpt-ad-1555649770722-0').length) {
    if((catfishUnit.css('display') == 'none')){ 
    // shareShw.addClass('catfish-extension');
  }
  else if(!$('.catfish-ads').hasClass('in')) {
    shareShw.removeClass('catfish-extension');
    // alert('test')
  }
  else {
  }
});

