/* ---------------------------------------------- /*
 * News (Articles)
/* ---------------------------------------------- */
//- Namespace
var newsNS = {
  //- Function
  //- Content Beautify
  newsContentBeautify : function (nSelector) {
    
    var nSelector = typeof nSelector !== 'undefined' ? $(nSelector) : $('.news-details .news-body'),
        nImage    = nSelector.find('img'),
        nBlockquote    = nSelector.find('blockquote');
    // nImage.each(function (e) {
    //   var nThis   = $(this),
    //       nWidth  = nThis.width(),
    //       nAlt    = nThis.attr('alt'),
    //       nTitle  = nThis.attr('title'),
    //       nClass  = nThis.attr('class'),
    //       nStyle  = nThis.attr('style'),
    //       nSFloat = nThis.css('float');          
      
    //   if (nThis.parent().get(0).tagName !== 'FIGURE') {
    //     var determiner;
        
    //     if (typeof nAlt !== 'undefined' && typeof nTitle !== 'undefined' && nAlt === nTitle)
    //       determiner = typeof nAlt !== 'undefined' ? '<figcaption>' + nAlt + '</figcaption>' : ''
    //     else
    //       determiner = typeof nAlt !== 'undefined' ? '<figcaption>' + (typeof nTitle !== 'undefined' ? '<span class="color-dark">' + nTitle + '</span> &mdash; ' : '') + nAlt + '</figcaption>' : '<figcaption>' + nTitle + '</figcaption>'

    //     nThis
    //       .wrap('<figure' + (typeof nClass !== 'undefined' ? ' class="' + nClass + '"' : '') + '></figure>')
    //       .after(typeof nTitle !== 'undefined' ? '<figcaption>' + nTitle + '</figcaption>' : '')
    //       .removeAttr('class');

    //     if (nThis.closest('p').length > 0)
    //       nThis.parent().unwrap()
    //   }

    //   if (!nThis.parents().hasClass('news-aside')) {
    //     if (typeof nStyle == 'undefined') {
    //       nThis.parent().css('max-width', nWidth);
    //     } else {
    //       nThis.parent().attr('style', nStyle);
    //       nThis.parent().css({
    //         'width': '',
    //         'max-width': nWidth,
    //         'height': 'auto'
    //       });
    //       nThis.removeAttr('style');
    //     }
    //     if (document.documentMode || /Edge/.test(navigator.userAgent))
    //       nThis.parent('figure:nth-of-type(1)').addClass('first edge').removeAttr('style')
    //     else
    //       nThis.parent('figure:nth-of-type(1)').addClass('first').removeAttr('style')
    //   }
    // });

    // nImage.each(function () {
    //   var nThis = $(this),
    //       nWidth = nThis.width(),
    //       nAlt = nThis.attr('alt'),
    //       nTitle = nThis.attr('title'),
    //       nClass = nThis.attr('class'),
    //       nStyle = nThis.attr('style'),
    //       nSFloat = nThis.css('float');

    //   if (nThis.closest('p').length > 0) {
    //     nThis.closest('p').css('padding', '0 0 30px');
    //   }

    //   if (nThis.parent().get(0).tagName !== 'FIGURE') {
    //     var determiner;
        
    //     // gnrt caption 
    //     if (typeof nAlt !== 'undefined' && typeof nTitle !== 'undefined' && nAlt === nTitle) {
    //       determiner = nAlt ? '<figcaption>' + nAlt + '</figcaption>' : '';
    //     } else {
    //       determiner = nAlt 
    //         ? '<figcaption>' + (nTitle ? '<span class="color-dark">' + nTitle + '</span> &mdash; ' : '') + nAlt + '</figcaption>'
    //         : (nTitle ? '<figcaption>' + nTitle + '</figcaption>' : '');
    //     }
    
    //     // Check image if wrapped in a link
    //     var parentTag = nThis.parent().get(0).tagName;
    //     if (parentTag === 'A') {
    //       // Wrap the <a> tag in a <figure> instead
    //       nThis.parent().wrap('<figure' + (nClass ? ' class="' + nClass + '"' : '') + '></figure>')
    //            .after(determiner);
    //     } else {
    //       nThis.wrap('<figure' + (nClass ? ' class="' + nClass + '"' : '') + '></figure>')
    //            .after(determiner);
    //     }
    
    //     nThis.removeAttr('class');

    //     if (nThis.closest('p').length > 0) {
    //       nThis.parent().unwrap();
    //     }
    //   }
    
    //   // Apply styling and ensure max width
    //   if (!nThis.parents().hasClass('news-aside')) {
    //     if (typeof nStyle === 'undefined') {
    //       nThis.parent().css('max-width', nWidth);
    //     } else {
    //       nThis.parent().attr('style', nStyle);
    //       nThis.parent().css({
    //         'width': '',
    //         'max-width': nWidth,
    //         'height': 'auto'
    //       });
    //       nThis.removeAttr('style');
    //     }
    
    //     if (document.documentMode || /Edge/.test(navigator.userAgent)) {
    //       nThis.parent('figure:nth-of-type(1)').addClass('first edge').removeAttr('style');
    //     } else {
    //       nThis.parent('figure:nth-of-type(1)').addClass('first').removeAttr('style');
    //     }
    //   }
    
    //   // Hide figcaption if it has no text
    //   nThis.siblings('figcaption').each(function () {
    //     if (!$(this).text().trim()) {
    //       $(this).remove();
    //     }
    //   });
    // });

    nImage.each(function (index) {
      var nThis = $(this),
          nWidth = nThis.width(),
          nAlt = nThis.attr('alt'),
          nTitle = nThis.attr('title'),
          nClass = nThis.attr('class'),
          nStyle = nThis.attr('style'),
          nSFloat = nThis.css('float');
          
      if (nThis.closest('p').length > 0) {
        nThis.closest('p').css('padding', '0 0 30px');
      }
      
      if (nThis.parent().get(0).tagName !== 'FIGURE') {
        // Check if the image is wrapped in a link
        var parentTag = nThis.parent().get(0).tagName;
        if (parentTag === 'A') {
          
          if (!nThis.parent().parent().is('figure')) {
            nThis.parent().wrap('<figure' + (nClass ? ' class="' + nClass + '"' : '') + '></figure>');
          }
        } else {
          
          if (!nThis.parent().is('figure')) {
            nThis.wrap('<figure' + (nClass ? ' class="' + nClass + '"' : '') + '></figure>');
          }
        }
    
        // Check if <figcaption> already exists; if not, create it
        if (!nThis.siblings('figcaption').length) {
          var determiner = '';
          if (typeof nAlt !== 'undefined' && typeof nTitle !== 'undefined' && nAlt === nTitle) {
            determiner = nAlt ? '<figcaption>' + nAlt + '</figcaption>' : '';
          } else {
            determiner = nAlt 
              ? '<figcaption>' + (nTitle ? '<span class="color-dark">' + nTitle + '</span> &mdash; ' : '') + nAlt + '</figcaption>'
              : (nTitle ? '<figcaption>' + nTitle + '</figcaption>' : '');
          }
    
          nThis.after(determiner);
        }
    
        nThis.removeAttr('class');
    
        // Unwrap <p> if it contains the image
        if (nThis.closest('p').length > 0) {
          nThis.parent().unwrap();
        }
      }

      if (!nThis.parents().hasClass('news-aside')) {
        if (typeof nStyle === 'undefined') {
          nThis.parent().css('max-width', nWidth);
        } else {
          nThis.parent().attr('style', nStyle);
          nThis.parent().css({
            'width': '',
            'max-width': nWidth,
            'height': 'auto'
          });
          nThis.removeAttr('style');
        }
    
        if (document.documentMode || /Edge/.test(navigator.userAgent)) {
          nThis.parent('figure:nth-of-type(1)').addClass('first edge').removeAttr('style');
        } else {
          nThis.parent('figure:nth-of-type(1)').addClass('first').removeAttr('style');
        }
      }
    
      // Hide figcaption if it has no text
      nThis.siblings('figcaption').each(function () {
        if (!$(this).text().trim()) {
          $(this).remove();
        }
      });
    });
    
    

  
    
    
    nBlockquote.each(function (e) {
      $(this).addClass('news-quote');
    });
  },
  //- Iframe Beautify
  newsIframeBeautify : function (nSelector) {
    var nSelector = typeof nSelector !== 'undefined' ? $(nSelector) : $('.news-details .news-content'),
        nIframe   = nSelector.find('iframe');
    
    nIframe.each(function (e) {
      var nThis   = $(this),
          nWidth  = nThis.attr('width') ? nThis.attr('width') : 600,
          nHeight = nThis.attr('height') ? nThis.attr('height') : 320,
          nRatio  = nHeight / nWidth * 100,
          nRatios = nRatio.toFixed(4) + '%';
      
      if (nThis.parent().get(0).tagName !== 'FIGURE') {
        nThis
          .wrap('<figure class="embed-frame"></figure>')
          .parent()
          .css({ 'padding-bottom': nRatios });
        if (nThis.closest('p').length > 0)
          nThis.parent().unwrap()
      }

      // Dev: print all attributes
      // $.each(this.attributes, function () {
      //   if (this.specified) {
      //     var aname = '';
      //     switch (this.name) {
      //       case this.name:
      //         aname = (this.value !== null && this.value.length) ? this.value : this.name;
      //         break;
      //     }
      //   }
      // });

    });
  },  
}

window.newsui = newsNS;

$(document).ready(function() {  
  newsui.newsIframeBeautify();

  // var pgNews = 'body.page-content',
  //     newsMeta = $(pgNews + ' .entry-meta'), 
  //     newsCarousel = $(pgNews + ' .news-owl');
  //     carouselElem = $(pgNews + ' .news-body.content .news-owl.owl-carousel'),
  //     firstP = $('body.page-content .news-body.content p:first');
  
    // if($(bodyContent).hasClass('page-content-1600099')) {
    //   newsMeta.css('margin-top', '30px');
    //   // firstP.append(newsMeta);
    //   japanCarousel.append(newsMeta);
    //   newsMeta.show();
    // }
    // else if($(bodyContent).hasClass('page-content-1635763')) {
    //   newsMeta.css('margin-top', '30px'); 
    //   libCarousel.append(newsMeta);
    //   newsMeta.show();
    // }
    // else if($(bodyContent).hasClass('page-content-1615171')) {
    //   newsMeta.css('margin-top', '30px'); 
    //   pprCarousel.append(newsMeta);
    //   newsMeta.show();
    // }
    // if(newsCarousel.length) {
    //   newsMeta.css('margin-top', '30px'); 
    //   carouselElem.append(newsMeta);
    //   newsMeta.show();
    //   console.log('gallery placement')
    // }
    // else {
    //   if(newsTopImg.length) {
    //     firstP.append(newsMeta);
    //     // newsMeta.show();
    //     console.log('no img onload');
    //   }
    //   else {
    //     firstP.append(newsMeta);
    //     newsMeta.show();
    //     console.log('no img ready');
    //   }
    // }
  // $('.news-body.content').html('.news-body.content').html().replace('<p>&nbsp;</p>', ''));
  fixWrap();
});

function fixWrap(){
    // console.log('fixWrap');
    var elements = '';
    elements += 'body.page-news #bigleaderboard .wide-ad, ';
    elements += 'body.page-node-29 #bigleaderboard .wide-ad, ';
    elements += 'body.page-pullout #bigleaderboard .wide-ad, ';
    elements += 'body.page-edgeproptv #bigleaderboard .wide-ad';

    var wraps = '';
    wraps += '.page-news .wrap, ';
    wraps += '.page-node-29 .wrap, ';
    wraps += '.page-pullout .wrap, ';
    wraps += '.page-edgeproptv .wrap';

    var ProdLeaderboard = $(elements), 
      MainWrap = $(wraps);
    
    // check leaderboard child existance
    if(ProdLeaderboard.length){
      // check wideAd display style 
      setTimeout(function(){
        // if ad exists
        if(ProdLeaderboard.css('display') == 'block'){
          MainWrap.addClass('wrap-top-0');   
        }
        else {
          MainWrap.removeClass('wrap-top-0'); 
        }
      }, 1000);
    }
}

$(window).load(function() { 
  newsui.newsContentBeautify();
  var newsMeta = $('body.page-content .entry-meta'),
      pgNews = 'body.page-content',
      newsMeta = $(pgNews + ' .entry-meta'),
      newsAuthor = $(pgNews + ' .entry-meta .em-author'),
      bodyContent = $(pgNews), 
      topImg = $(pgNews + ' .news-body.content figure.caption.first'), 
      topImg2 = $(pgNews + ' .news-body.content figure.first'),
      topImg3 = $(pgNews + ' .news-body.content p figure.caption.first'),
      firstP = $('body.page-content .news-body.content p:first'),
      secondP = $('body.page-content .news-body.content p:nth-child(2)'),
      firstPiFrame = $('body.page-content .news-body.content p:first iframe'),
      topImg4 = $(firstP).find('figure'),
      newsCarousel = $(pgNews + ' .news-owl'),
      newsDate = $(pgNews + ' .em-date' ),
      carouselElem = $(pgNews + ' .news-body.content .news-owl.owl-carousel'),
      case1Body = $('body.page-content-1900284'),
      case1 = $('#img-1900284');
      // case1 = '<div id="case-1900284"></div>';


  // Please enable this to put back the meta dom position
  // if(newsCarousel.length) {
  //   newsMeta.css('margin-top', '30px'); 
  //   carouselElem.append(newsMeta);
  //   newsMeta.show();
  //   console.log('meta with gallery');
  // }
  // else if((topImg.length) && (!firstPiFrame.length)) {
  //   newsMeta.css({'margin-top': '30px', 'text-align': 'left'}); 
  //   newsDate.css({'border-bottom' :'6px solid #fff'});
 
  //   if(case1Body.length) {  
  //     newsMeta.css({'margin-top': '0', 'padding-bottom': '10px', 'text-align': 'left'});  
      
  //     case1.append(newsMeta); 
  //     newsMeta.show();
  //     console.log('1900284 case topImg');
  //   }
  //   else {
  //     topImg.append(newsMeta);
  //     newsMeta.show();
  //     console.log('meta topImg');
  //   }
  // }

  // else if((topImg2.length) && (!firstPiFrame.length)) {
  //   firstP.prepend(newsMeta);
  //   newsMeta.css({'padding-left': '0'});
  //   newsMeta.show();
  //   console.log('meta topImg2');
  // }

  
  // else if ($(firstPiFrame).length > 0) {  
  //   newsMeta.css({"margin-top": "30px", "padding-left": "0", "margin-bottom": "-25px"});
  //   firstP.append(newsMeta); 
  //   newsMeta.show();
  //   console.log('meta video iframe');
  // }

  // else if($(newsAuthor).text() == '') {
  //   newsMeta.css({'border-top': '1px solid #fff'});
  //   newsMeta.css({'padding-left': '0'}); 
  //   firstP.prepend(newsMeta);
  //   newsMeta.show();
  // }
  
  // else {
  //   firstP.prepend(newsMeta);
  //   newsMeta.css({'padding-left': '0'})
  //   newsMeta.show();
  //   console.log('meta no top img');
  // }
  // Please enable this to put back the meta dom position
    
});


 // fix page events leaderboard wrap top position
 window.addEventListener('load',function(){
  fixWrapPageEvents();
 });

  function fixWrapPageEvents(){
    // wait jquery loaded for pageevents
    (function( $ ) {
      var ProdLeaderboard = $('body.page-events #bigleaderboard .wide-ad'), 
        MainWrap = $('.page-events .wrap');
      // check leaderboard child existance
      if(ProdLeaderboard.length){
        // check wideAd display style 
        setTimeout(function(){
          // if ad exists
          if(ProdLeaderboard.css('display') == 'block'){
            MainWrap.addClass('wrap-top-0');   
          }
          else {
            MainWrap.removeClass('wrap-top-0');
          }
        }, 1000);
      }
    })(jQuery);
  }

//- News Action -- Social sharing
var nShareCloseBtn = '.news-action .social-media .close-toggle';
$(document).on('click', nShareCloseBtn, function(e) {
  if ($(this).closest('ul').find('li').length > 4)
    $(this).closest('ul').find('li:nth-child(n+5)').toggleClass('active')
  $(this).find('.ion-icon').toggleClass('ion-ios-more ion-ios-close-empty')
});

$(window).load(function() { 
// $(document).ready(function() {
  var propPlacement = $('body.page-content .news-body.content p:nth-child(5)'),
      propFallback = $('body.page-content .news-body.content p:nth-child(4)'),
      prop1906326 = $('body.page-content .news-body.content p:nth-child(6)'),
      propPlc = $('body.page-content .news-body.content #block-block-5'),
      propPara = $('body.page-content .news-body.content p:first'),
      newsParagraph = $('body.page-content .news-body.content p'),
      // newsTopProp = $('body.page-content .featured-properties'),
      adsInContent = $('body.page-content #adInContent'),
      mrecBlock10 = $('body.page-content-1904346 #block-block-10'),
      mrecBlock11 = $('body.page-content-1904346 #block-block-11'),
      em1904346 = $('body.page-content-1904346 .news-body.content em'),
      lastTable1904346 = $('body.page-content-1904346 .news-body.content table:last');

  if(propPlacement) { 
    adsInContent.appendTo(propPlacement);
  }
  if (propPlacement.length === 0) {
    adsInContent.appendTo(propFallback);
  }
  // Widget meta issue: Remove newsTopProp if the body has the specified class
  // if ($('body').hasClass('page-content-1909420')) {
  //   newsTopProp.remove();  
  // }
  if($('body').hasClass('page-content-1906416')) {
    adsInContent.appendTo(propFallback);
  }
  if($('body').hasClass('page-content-1906326')) {
    adsInContent.appendTo(prop1906326);
  }
  if($('body').hasClass('page-content-1904346')) {
      console.log('1904346');
      mrecBlock10.hide();
      mrecBlock11.hide();
  }
  var iframe = $('.widget-tem-search.news-banner-ad').find('iframe');

  iframe.on('load', function() {
    $(this).contents().find('body').css('margin-top', '83px');
  });
});

 