/* ---------------------------------------------- /*
 * Listing
/* ---------------------------------------------- */
// Carousel
$(window).load(function() {
  var $sale_detail_slideshow_caption = $('.slideshow-wrap > .slideshow-caption');
  var $sale_detail_slideshow = $('#sale-detail-slideshow');
  var $sale_detail_carousel = $('#sale-detail-carousel');
  var slidesPerPage = 6; //globaly define number of elements per page
  var slidesPerPageMob = 4;
  var syncedSecondary = true;
  /* Making sure that slideshow and carousel exist */
  if( $sale_detail_slideshow.length > 0 && $sale_detail_carousel.length > 0){
    $sale_detail_slideshow.owlCarousel({
      margin: 0,
      items: 1,
      // lazyLoad: true,
      dots: false,
      slideSpeed : 2000,
      autoHeight: true,
      nav: true,
      loop: true,
      responsiveRefreshRate : 200,
      navText: ['<i class="ion-ios-arrow-left"></i>','<i class="ion-ios-arrow-right"></i>'],
      onInitialized: function(event){
        $sale_detail_slideshow_caption.detach().appendTo( $sale_detail_slideshow ).removeClass('hidden');
      },
    }).on('changed.owl.carousel', syncPosition);

    $sale_detail_carousel
      .on('initialized.owl.carousel', function () {
        $sale_detail_carousel.find(".owl-item").eq(0).addClass("current");
      })
      .owlCarousel({
        nav: false,
        dots: false,
        smartSpeed: 200,
        slideSpeed : 500,
        navText: ['<i class="ion-ios-arrow-left"></i>','<i class="ion-ios-arrow-right"></i>'],
        slideBy: slidesPerPage, //alternatively you can slide by 1, this way the active slide will stick to the first item in the second carousel
        responsiveRefreshRate : 100,
        responsive : {
          0 : {
            items: slidesPerPageMob,
            margin: 5
          },
          768 : {
            items : slidesPerPage,
            margin: 5
          }
        }
    }).on('changed.owl.carousel', syncPosition2);

    function syncPosition(el) {
      // if you set loop to false, you have to restore this next line
      // var current = el.item.index;

      // if you disable loop you have to comment this block
      var count = el.item.count-1;
      var current = Math.round(el.item.index - (el.item.count/2) - .5);

      if(current < 0) {
        current = count;
      }
      if(current > count) {
        current = 0;
      }

      // end block

      $sale_detail_carousel
        .find(".owl-item")
        .removeClass("current")
        .eq(current)
        .addClass("current");
      var onscreen = $sale_detail_carousel.find('.owl-item.active').length - 1;
      var start = $sale_detail_carousel.find('.owl-item.active').first().index();
      var end = $sale_detail_carousel.find('.owl-item.active').last().index();

      if (current > end) {
        $sale_detail_carousel.data('owl.carousel').to(current, 100, true);
      }
      if (current < start) {
        $sale_detail_carousel.data('owl.carousel').to(current - onscreen, 100, true);
      }
    }

    function syncPosition2(el) {
      if(syncedSecondary) {
        var number = el.item.index;
        $sale_detail_slideshow.data('owl.carousel').to(number, 100, true);
      }
    }

    $sale_detail_carousel.on("click", ".owl-item", function(e){
      e.preventDefault();
      var number = $(this).index();
      $sale_detail_slideshow.data('owl.carousel').to(number, 300, true);
    });
  }
});

$(window).on('load resize', function() {
  if (window.outerWidth > 991 ) {
    var sideAffix     = $('.sidebar-affix'),
        sideAffixStop = $('.sidebar-affix-stop'),
        saOffsetTop   = sideAffix.length ? sideAffix.offset().top : 'Affix not found',
        saOffsetOff   = sideAffixStop.length ? sideAffixStop.offset().top : 'Affix not found',
        saFullHeight  = $('body').innerHeight(),
        saHeaderNav   = $('.header').height();
    // if (sideAffix.length && sideAffixStop.length)
    //   console.log(saFullHeight + ' - ' + saOffsetOff + ' = ' + (saFullHeight - saOffsetOff))
    sideAffix.affix({
      offset: {
        top: saOffsetTop - saHeaderNav - 10,
        bottom: function () {
          return (this.bottom = saFullHeight - saOffsetOff + 30)
        }
      }
    });
  }
});