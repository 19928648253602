// $(document).ready(function() {
//   //gold winner popup 

//   function closePopup() {
//     if($('.gold-winner-popup').hasClass('active')) {
//       $('.gold-winner-popup').click(function () {
//         $(this).slideUp(300).removeClass('active');
//       });
//     }
//   };
//   $('#bma-page.2020 #pop1').click(function () {
//     $('.gold-winner-popup.pop1 ').addClass('active').slideDown(300);
//     closePopup(); 
//   });
//   $('#pop2').click(function () {
//     $('.gold-winner-popup.pop2').addClass('active').slideDown(300);
//     closePopup();
//   });
  
// }); 


 

