(function ($) {
  $( document ).ready(function() {       
    //State card for newlaunch
    $('.state_card .cover').on('click', function() {
        $(this).find('.card-label').text('Kedah');
        $(this).find('.card-label').addClass('revert');
        $('.state_card .card-box:nth-child(n+8)').css("display","block");

    });
  }); 
})(jQuery);