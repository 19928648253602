
  var ptype = '';
  var select_asset = 0;
  var select_state = '';
  function show(aval) {
    if (aval == "l") {
        ptype = 'l';
        $('#hiddenLanded').slideToggle();
        $('#hiddenNonLanded').slideUp();
        $("#address-landed").val("");

        
        // Instant search landed
        $("#address-landed").keyup(function(e){
        var q = $("#address-landed").val();
        $.getJSON("https://www.edgeprop.my/index.php?q=erp/autocomplete/l/"+q,
        
        function(data) {
          $("#results").empty();
          
          $.each(data, function(i,item){
            //item is basically the json response value; i = key, item = value
            var item_new = item.replace(", "," ");
            item_new = item_new.replace(", "," ");
            var expr_main = q;//expression to search
            var expr_upper = q.toUpperCase();

          
            if( q && (item_new.includes(expr_upper) || item_new.includes(expr_main))  ){
                var x = i.split(">>");
                var y = x[1].split("/");

                $("#results").append("<li class='list-search' data-state='"+y[0]+"' data-asset='"+y[1]+"'>" + item + "</li>");
                $("#results").css("display", "block");

                //clicking list to set input
                $(".list-search").click(function(){
                    $("#address-landed").val($(this).text());
                    select_state = $(this).attr('data-state');
                    select_asset = $(this).attr('data-asset');
                    $("#results").css("display", "none");
                });
                //hide div when mouse outside the div
                $("#results").mouseleave(function() {
                       $(this).css("display", "none");
                });

            }
            
          });
        });
      });
    }
    else if (aval == "r") {
        ptype = 'r';
        $('#hiddenNonLanded').slideToggle();
        $('#hiddenLanded').slideUp();
        $("#address-nonlanded").val("");


        //instant search non landed
        $("#address-nonlanded").keyup(function(e){
        var q = $("#address-nonlanded").val();

        $.getJSON("https://www.edgeprop.my/index.php?q=erp/autocomplete/r/"+q,
        function(data) {
          $("#nonlanded-results").empty();
          
          $.each(data, function(i,item){
          
          
            //item is basically the json response value; i = key, item = value
            var item_new = item.replace(", "," ");
            item_new = item_new.replace(", "," ");
            var expr_main = q;
            var expr_upper = q.toUpperCase();
          
            if( q && (item_new.includes(expr_upper) || item_new.includes(expr_main))){
                var x = i.split(">>");
                var y = x[1].split("/");
              
                $("#nonlanded-results").append("<li class='list-search' data-state='"+y[0]+"' data-asset='"+y[1]+"'>" + item + "</li>");
                $("#nonlanded-results").css("display", "block");

                //clicking list to set input
                $(".list-search").click(function(){
                    $("#address-nonlanded").val($(this).text());
                    select_state = $(this).attr('data-state');
                    select_asset = $(this).attr('data-asset');
                    $("#nonlanded-results").css("display", "none");
                });

                //for hiding the div when mouse is outside of the div
                $("#nonlanded-results").mouseleave(function() {
                       $(this).css("display", "none");
                });

            }
            
          });
        });
      });

    } 
    else{
     $('#hiddenLanded, #hiddenNonLanded').slideUp();
    }
  }
  $(document).ready(function(){

    $('#ReportSubmit').click(function(){

      var asset = select_asset;   
      var property_type = $('#property_type option:selected').val();
      var sub_type = $('#sub_type option:selected').val();
      var block = $('#block').val();
      var level = $('#level').val();
      var built_up = $('#built_up').val();
      var built_up_nl = $('#built_up_nl').val();
      var land_area = $('#land_area').val();
      var name = $('#name').val();
      var email = $('#email').val();
      var contact = $('#contact').val();
     

      var  submitUrl = '';
      submitUrl = "https://marktwo.theedgeproperty.com.my/erp_report_queue/add";
      submitUrl += "?asset_id="+asset;
      submitUrl += "&property_type="+property_type;
      submitUrl += "&sub_type="+sub_type;
      submitUrl += "&block="+block;
      submitUrl += "&level="+level;
      if (property_type == 'l'){
        submitUrl += "&built_up="+built_up;
      }
      else {
        submitUrl += "&built_up="+built_up_nl;
      } 
      submitUrl += "&land_area="+land_area;
      submitUrl += "&name="+name;
      submitUrl += "&email="+email;
      submitUrl += "&contact="+contact;
      submitUrl += "&state="+ select_state;
 

      if (asset==null || asset=="") {  
          alert("Projects / Townships / Street name can't be empty");
          return false;

      } else if ( property_type =="l" &&  sub_type=="0") {   
          alert("Please choose your sub property type");  
          return false;     

      } else if ( property_type =="l" && (built_up==null || built_up==""))   {   
          alert("The built up input can't be empty");  
          return false;  

      } else if (property_type =="r" && (built_up_nl==null || built_up_nl==""))  {   
          alert("The built up input can't be empty");  
          return false;  

      } else if ( property_type =="l" && (land_area == null || land_area == "")) {   
          alert("The land size input can't be empty");  
          return false; 

      } else if (name==null || name=="") {   
          alert("Your name can't be empty");  
          return false; 

      // } else if (email==null || email=="") {   
      //     alert("Your email can't be empty");  
      //     return false; 

      } else if (email==null || email=="") {
          alert("Your email can't be empty");
          return false;

      } else if (validateEmail(email)) {

      } else if (contact==null || contact=="") {
          alert("Your contact number can't be empty");
          return false;

      } else if (validateContact(contact)) {

      // } else if (contact==null || contact=="") {   
      //     alert("Your contact number can't be empty");  
      //     return false;  
      // } 
      } else {
          alert("Please enter a valid email address  (e.g. me@myemail.com)");
          return false; 
      }

      function validateEmail(email) {
          var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
          if (filter.test(email)) {
              return true;
          }
          else {
              return false;
          }
      }

      function validateContact(contact) {
          var filterContact = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
          if (filterContact.test(contact)) {
              return true;
          }
          else {
              return false;
          }
      }


      console.log(submitUrl);

      $.getJSON(submitUrl,
        function(data) {
          if (data == 'success'){
            console.log("redirecting..");
            window.location.replace("homeadvisor-success.html");
          }
        }
      );
    });
  })