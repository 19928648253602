// jQuery(function($) {  

// window.mailchimp = {
    
//   subscribe : function (){
//     $('form').on('submit', $('.mc-embedded-subscribe-form'), function (e) {
//       e.preventDefault();      
//       var url = $(this).attr("action");  
//       url = url.replace("/post?u=", "/post-json?u=");
//       url += "&c=?";
//       var email = $('.mce-EMAIL', this).val();
//       var data = {EMAIL:email};
//       var error_result = $('.mce-error-response', this);
//       var success_result = $('.mce-success-response', this);

//       if (isEmail(email)){
//         $.ajax({
//           url: url,
//           type: 'GET',
//           dataType: 'json',
//           data : data,
//           contentType: "application/json; charset=utf-8",
//           success: function (resp) {
//             if (resp.result != "success") {
//               error_result.show();
//               var err_html = $.parseHTML(resp.msg);
//               error_result.html(resp.msg);
//               success_result.hide();          
//             }else{
//               success_result.show();
//               success_result.text(resp.msg);
//               error_result.hide();
//             }
//           }
//         });
//       }
//       else{
//         msg = "Please fill in available email address.";
//         $('.mce-error-response', this).text(msg);
//         $('.mce-error-response', this).css('display','block');
//       }      
//     });
//   },
//   focus: function(){
//     $input = $('.i-form .input.animate input');

//     $input.on('focus', function(){
//       $(this).closest('.input').addClass('focused');
//     });

//     $input.on('blur', function(){
//       $email = $(this).closest('.mce-EMAIL');
//       var value = $email.val();
//       if(value.length>0){
//         return;
//       }
//       else{
//         $(this).closest('.input').removeClass('focused');
//       }    
//     });
    
    
    
//   }
// }
// });

// function isEmail(email) {
//   var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
//   return regex.test(email);
// }
