/* ---------------------------------------------- /*
 * Header
/* ---------------------------------------------- */
// Sticky
// $(window).on('load scroll', function() {
//   var wHeight  = $(window).height(),
//       bHeight  = $('body').height(),
//       cWrapper = $('.header'),
//       cHeight  = cWrapper.height(),
//       cOffset  = cWrapper.offset(),
//       tWrapper = cWrapper.find('.header-top'),
//       nWrapper = cWrapper.find('.header-nav'),
//       fWrapper = $('.front-wrapper');
//   // console.log('wHeight: ' + wHeight +', ' +
//   //             'bHeight: ' + bHeight +', ' +
//   //             'cHeight: ' + cHeight + ', ' +
//   //             'cOffset: ' + cOffset.top);
//   if (window.outerWidth > 767 && window.innerWidth > 767) {
//     if ($(this).scrollTop() > cHeight) {
//       if (!cWrapper.hasClass('force-sticky')) {
//         cWrapper.addClass('sticky');
//       }
//       fWrapper.addClass('sticky');
//     } else {
//       cWrapper.removeClass('sticky');
//       fWrapper.removeClass('sticky');
//     }
//     if (cWrapper.hasClass('force-sticky')) {
//       cWrapper.addClass('sticky');
//       $('body').animate({'padding-top': nWrapper.height() }, 'fast');
//     }
//   }
// });

$(window).on('load scroll', function() {
  var headerTop = $('.header-top'),
      body = $('body');
  if(body.is('.page-agent-search, .page-agent, .area-outlook-landing, .project-outlook-landing, .page-node-15, .page-transaction, .page-edgehome2k18')){
    if ($(document).scrollTop() > 50) {
      $('.header-top').addClass('header-color');
    }
    if ($(document).scrollTop() < 50) {
      $('.header-top').removeClass('header-color');
    }
  }    
});
// Navbar Collapse
$(window).on('load resize', function() {
  var screenSize = window.innerWidth,
      navLink = $('.nav-link'),
      navSubs = navLink.children('li'),
      navSize = navLink.innerWidth(),
      hdPanel = $('.header-panel').outerWidth(true),
      hdBrand = $('.header-brand').outerWidth(true),
      hdNavi  = $('.header-nav').outerWidth(true);
  if (screenSize > 1024) {
    // console.log(screenSize + ' - ' + hdNavi + ' = ' + parseInt(parseInt(screenSize - hdNavi) - parseInt(hdBrand + hdPanel + 50)));
    // console.info('Brand: ' + hdBrand + ' / Panel: ' + hdPanel + ' = Total: ' + parseInt(hdBrand + hdPanel + 50));
    if (hdNavi > screenSize - parseInt(hdBrand + hdPanel + 50)) {
      if (navSubs.length > 7) {
        $(navSubs).slice(-2).wrapAll('<li class="dropdown drop-more"><ul class="dropdown-menu"></ul></li>');
        $('.drop-more > .dropdown-menu').before('<a href="#" class="dropdown-toggle" data-toggle="dropdown" data-hover="dropdown"><i class="material-icons">more_vert</i></a>');
      }
    }
  }
});