$(document).ready(function() {
	// $(window).scroll(function() {
	// 	var storiesBar = $('body.page-content .news-top-stories.news-slider');
	// 	if (window.scrollY > 1) {
	// 		storiesBar.addClass('pos-fixed newscroll');
	// 	} else {
	// 		storiesBar.removeClass('pos-fixed newscroll');
	// 	}
    // });
    var mainNewsContent = $('body.page-content .wrap.news-wope-theme');

    // if(mainNewsContent) {
    //     $(this).css({'margin-top': '186px'});
    //     console.log('test newspage JS');
    // }

});