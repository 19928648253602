// $(document).ready(function() {
$(window).load(function() {
  var dfpBndrlElemPre          = '#div-gpt-ad-',
      dfpBndrlId               = '1542075954162-0', // prod:1542075954162,  staging: 1523337576043
      excptThisPage             = 'page-node-37', //dkn page
      dfpBndrlFullId           = '.banderole' + ' ' + dfpBndrlElemPre + dfpBndrlId;
      // console.log(dfpBndrlFlag);

  var expBndrlTime = new Date();
  var bndrlMinute = 0;
  expBndrlTime.setTime(expBndrlTime.getTime() + (bndrlMinute * 60 * 1000));

    $gobndrl = $.cookie('banderole-cookie');
    if ($gobndrl == null) {
        $.cookie('banderole-cookie', 'test', { path: '/', expires: expBndrlTime });
          // if(stoAd.length > 0){

        if(($(dfpBndrlFullId).css('display') == 'none')){ 
            $('.banderole').hide();
             console.log('banderole display none');
        }
        else if ($('body').hasClass(excptThisPage)) {
          $('.banderole').hide(); 
          // alert('dkn page');
        }
        else {
          $('.banderole').show();
          $('.banderole .slide-trigger').addClass('on');

          $('.banderole .slide-trigger.trigger').on('click', function(){
            $(this).parent().parent().toggleClass('in');
          });
          $('.banderole .slide-close').on('click', function(){
            $(this).parent().parent().removeClass('in');
          });

          $('.banderole .trigger-close').on('click', function(){
            $('.banderole .slide-trigger').addClass('trigger-in');
          });

          if ($('body').hasClass('front')) {
            setTimeout(function(){
              $('.banderole').addClass('in');
            },300);
          }
          else {
            // do not show the slide in the beginning
          }
          // console.log('banderole display block');
        }
        // console.log('create banderole cookie');
    }
    else {
      $('.banderole').hide();
      console.log('banderole cookie is working');
    }
});

$(window).scroll(function() {
  var banderole = $('.banderole');
  if(banderole.hasClass('in')){
    banderole.removeClass('in');
  }
});

// document.ready(function() {
//   if (window.location.href.indexOf('https://www.maybank2own.com/portal/')) {
//     //Hide the element.
//     $('.banderole').hide();
//   }
// });