
$(window).scroll(function() {
    var awardFBar = $('#awards2019 .floating-bar');
    if (window.scrollY > 100) {
        awardFBar.addClass('in');
    } else {
        awardFBar.removeClass('in');
        $('#awards2019 .floating-menu').removeClass('active');
    }
});

//floating bar
$('#bma-page .floating-menu').click(function () {
    // $('#bma-page .floating-menu').removeClass('active');
    $(this).addClass('active');   
});

$(window).scroll(function() {
    var bmaBar = $('#bma-page .floating-bar');
    if (window.scrollY > 100) {
        bmaBar.addClass('in');
    } else {
        bmaBar.removeClass('in');
        $('#bma-page .floating-menu').removeClass('active');
    }
});

// $(window).scroll(function() { 
//     if (window.scrollY < 100) {
//       $('#bma-page .floating-menu').removeClass('active');
//     } 
//     else if ($(window).width() >= 768) {
//       if (window.scrollY > 13000) {
//         $('#bma-page .floating-bar').removeClass('in');
//       } 
//       else {
//       }
//     }
//     // else if ($(window).width() < 768) {
//     //   if (window.scrollY > 2200) {
//     //     $('#bma-page .floating-menu').removeClass('active');
//     //   } 
//     //   else {
//     //   }
//     // }
// });

$(window).load(function() {
    //floating bar
    $('#bma-page .floating-menu').click(function () {
        $('#bma-page .floating-menu').removeClass('active');
        $(this).addClass('active');   
    });

    $(window).scroll(function() { 
        if (window.scrollY < 100) {
          $('#bma-page .floating-menu').removeClass('active');
        } 
        else if ($(window).width() >= 768) {
          if (window.scrollY > 17700) {
            $('#bma-page .floating-bar').removeClass('in');
          } 
          else {
          }
        }
        // else if ($(window).width() < 768) {
        //   if (window.scrollY > 2200) {
        //     $('#bma-page .floating-menu').removeClass('active');
        //   } 
        //   else {
        //   }
        // }
    });

    $(".floating-bar a[href^='#']").click(function(e) {
      e.preventDefault();
      
      var position = $($(this).attr("href")).offset().top;

      $("body, html").animate({
        scrollTop: position
      } /* speed */ );
    });

});




// console.log('test');


