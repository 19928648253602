$(document).ready(function() { 
    var baseEnlargeImg = "https://media.edgeprop.my/s3fs-public/editorial/my/";

    var imgG6 = baseEnlargeImg + "2024/Jan/15/139sites.jpg",
        img1909123a = baseEnlargeImg + "2024/April/26/Map1Segambut.jpg",
        img1909123b = baseEnlargeImg + "2024/April/26/Map2PetalingJaya.jpg",
        img1909123c = baseEnlargeImg + "2024/April/26/Map3Hartamas.jpg",
        img1909123d = baseEnlargeImg + "2024/April/26/Map4BdrSriDamansara.jpg",
        img1909481a = baseEnlargeImg + "2024/June/06/MRTLineGraph.png",
        img1909481b = baseEnlargeImg + "2024/June/06/MRTTable1.jpg",
        img1909481c = baseEnlargeImg + "2024/June/06/MRTBarChart.png",
        img1909481d = baseEnlargeImg + "2024/June/06/MRTTable2.jpg",
        img1909676a = baseEnlargeImg + "2024/June/27/EPIQscreenshot.png",
        img1909676b = baseEnlargeImg + "2024/June/27/Table1-High-rises-near-IC-Design-Park-R2.jpg",
        img1909676c = baseEnlargeImg + "2024/June/27/Table2-Upcoming-high-rise-properties-within-5km-of-IC-Design-Park-R2.jpg",
        img1910110a = baseEnlargeImg + "2024/August/09/2-KLofficebuildingcasestudy.png",
        img1910110b = baseEnlargeImg + "2024/August/09/1-Agingofficestock.png";

    $('img[src="' + imgG6 + '"]').addClass('enlrgImg');
    $('img[src="' + img1909123a + '"]').addClass('enlrgImg');
    $('img[src="' + img1909123b + '"]').addClass('enlrgImg');
    $('img[src="' + img1909123c + '"]').addClass('enlrgImg');
    $('img[src="' + img1909123d + '"]').addClass('enlrgImg');
    $('img[src="' + img1909481a + '"]').addClass('enlrgImg');
    $('img[src="' + img1909481b + '"]').addClass('enlrgImg');
    $('img[src="' + img1909481c + '"]').addClass('enlrgImg');
    $('img[src="' + img1909481d + '"]').addClass('enlrgImg'); 
    $('img[src="' + img1909676a + '"]').addClass('enlrgImg');
    $('img[src="' + img1909676b + '"]').addClass('enlrgImg');
    $('img[src="' + img1909676c + '"]').addClass('enlrgImg');
    $('img[src="' + img1910110a + '"]').addClass('enlrgImg');
    $('img[src="' + img1910110b + '"]').addClass('enlrgImg');
    // console.log('enlarge img');

    // Click event with proper class
    $(".enlrgImg").click(function () {
        var imgSrc = $(this).attr("src");
        $("#newsImgEnlargedImage").attr("src", imgSrc);
        $("#newsImgModalOverlay").fadeIn();
    });

    $("#newsImgModalOverlay").click(function () {
        $(this).fadeOut();
    });
});